<template lang="pug">
  .login-page
    section.login-section
      h1 Login.
      form.login-form( v-on:submit.prevent="login" )
        AppInput( label="Email:" type="email" placeholder="your@email.com" v-model="userEmail" )
        AppInput( label="Password:" type="password" v-model="userPassword" )
        a.forgot-password-link( @click='sendResetEmail' href="#" ) Forgot password
        AppButton( type="submit" ) LOGIN
</template>

<script>
import AppInput from '@/components/AppInput.vue'
import AppButton from '@/components/AppButton.vue'

import { auth } from '@/firebase.js'

export default {
  name: 'home',
  components: {
    AppInput,
    AppButton
  },
  data () {
    return {
      userEmail: '',
      userPassword: ''
    }
  },
  created () {
    // check if user is already logged in
    auth.onAuthStateChanged(user => {
      if (user) {
        this.$router.replace({ name: 'feeds' })
      }
    })
  },
  methods: {
    login () {
      const email = this.userEmail
      const password = this.userPassword
      if(!email || !password) return alert('Please enter a valid email and password')
      auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          this.$router.replace({ name: 'feeds' })
        })
        .catch(err => {
          // Handle Errors here.
          // const errorCode = err.code
          // const errorMessage = err.message
          // ...
          alert(err.message)
          console.error(err)
        })
    },
    sendResetEmail () {
      const email = this.userEmail
      if(!email) return alert('Please enter a valid email')
      auth.sendPasswordResetEmail(email)
        .then(() => {
          alert(`An email with a reset link has been sent to ${email}`)
          this.$ga.event({
            eventCategory: 'account',
            eventAction: 'Send pw reset email',
          })
        })
        .catch(e => alert(e.message))
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  color: white;
}

.login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.login-form > * {
  display: block;
}

.input-container {
  max-width: 400px;
  text-align: left;
}

.forgot-password-link {
  color: inherit;
}
</style>
